:root {
  --color-white: #ffffff;
  --color-secondary: #0000ff;
  --color-loading-bg-primary: #bababa;
  --color-loading-bg-secondary: rgb(95, 95, 95);
  --color-nav-bg: #1c1c1c;
  --color-nav-option-bg: #393939;
  --color-nav-white: #ffffff;
  --color-nav-active: #555555;
  --color-main-text: #000000;
  --color-border: #e0e0e0;
  --color-input-text: #818181;
  --color-input-border: rgb(224, 224, 224);
  --color-comments-bg: #fafafa;
  --color-refused: rgb(244, 204, 201);
  --color-draft: rgb(199, 199, 199);
  --color-abandonned: rgb(211, 147, 81);
  --color-submitted: rgb(217, 210, 233);
  --color-exchanged: rgb(217, 234, 211);
  --color-accepted: rgb(255, 242, 204);
  --color-available: rgb(207, 226, 243);
  --color-recycled: rgb(114, 114, 114);
  --color-analysis: rgb(252, 229, 205);
  --color-disabled: rgb(244, 204, 201);
  --color-pending: rgb(211, 147, 81);
  --color-enabled: rgb(217, 234, 211);
  --color-web-services: rgb(207, 226, 243);
  --color-required: #ff0000;
  --color-switch: #86d3ff;
  --color-switch-secondary: #2693e6;
  --color-input-background-secondary: #e5f4ff;
  --color-input-disable: #e9ecef;

  --size-global1: 0.9375rem;
  --size-global2: 0.875rem;
  --size-global3: 0.7rem;
  --size-head1: 16px;
  --size-head2: 15px;
  --size-head3: 14px;
  --size-head4: 13px;
  --size-head5: 12px;
  --size-head6: 11px;
  --size-product: 0.8rem;
}
