.comments-stl {
    /* background: #ffd; */
    padding: 2%;
    margin: 16px auto;
}

.comments-stl:hover {
    box-shadow: 0 5px 30px rgb(43 135 218 / 20%);
}

.comments-stl-2 {
    background: #ffd;
    padding: 2%;
    margin: 16px auto;
}

.comments-stl-2:hover {
    box-shadow: 0 5px 30px rgb(43 135 218 / 20%);
    background: #ffd;
}

.content-icon {
    display: flex;
    justify-content: flex-end;
}

.icon-stl {
    margin-right: 6px;
    padding: 4px;
    cursor:pointer;
}

.icon-stl:hover {
    background-color: rgba(222, 222, 222);
    cursor: pointer;
}
    