.containerBox {
  background-color: var(--color-white);
  border-radius: 10px;
}

.alet-warning {
  color: #897256;
  background: #efd9ba;
  border-color: #edc793;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.containerBoxHead {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 2px solid var(--color-input-border);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
}

.sectionBoxTop {
  border-bottom: 2px solid var(--color-border);
}

.sectionBoxBottom {
  border-top: 2px solid var(--color-border);
}
.imgSearch {
  padding: 10px 15px;
  border-radius: 5px;
}
.imgSearch:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
.grayBackgr {
  padding: 10px 15px;
  border-radius: 5px;
}

.grayBackgr:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}

.cssanimation,
.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInTop {
  animation-name: fadeInTop;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}
/* 
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
} */
