.pointer:hover {
  cursor: pointer;
  opacity: 0.7;
}

div.td-stl tr {
  vertical-align: middle;
  background: var(--color-white);
  border: solid 1.5px var(--color-border);
  text-align: center;
  font-weight: bold;
  margin: 0;
  padding: 20px;
}
.pad {
  padding: 0.1875rem 0.3125rem;
}
.tableStats {
  padding: 0;
}

.sectionColor {
  width: 30vw;
  display: flex;
}
.color {
  width: 20%;
}
@media (max-width: 3000px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.noPadding {
  padding: 0 !important;
}
tr.padi td:not(.noPadding) {
  padding: 10px 30px;
  text-align: center;
}
tr.padi td tr:not(.noPadding) {
  padding: 10px 30px;
  text-align: center;
}
