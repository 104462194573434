@media (max-width: 1600px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

td.comment-td {
  min-width: 300px !important;
}
th.comment-th {
  min-width: 300px !important;
}
