.containerBox {
  background-color: var(--color-white);
  border-radius: 10px;
}

.containerBoxHead {
  background-color: hsl(0, 0%, 100%);
  border-radius: 10px;
  border: 2px solid var(--color-input-border);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
}

.sectionBoxTop {
  border-bottom: 2px solid var(--color-border);
}

.sectionBoxBottom {
  border-top: 2px solid var(--color-border);
}
.imgSearch {
  padding: 10px 15px;
  border-radius: 5px;
}
.imgSearch:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
.grayBackgr {
  padding: 10px 15px;
  border-radius: 5px;
}

.grayBackgr:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
