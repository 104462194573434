* {
  box-sizing: border-box;
}
.m-title {
  margin-left: 5% !important;
  font-size: var(--size-head1);
  margin-top: 1px;
}
.m-title_info {
  margin-left: 5% !important;
  font-size: var(--size-head1);
  margin-top: 1px;
}

.icon_size {
  width: 50px !important;
  height: 50px;
}

.iconbar_size {
  width: 50px !important;
  height: 50px;
}

.iconbar_size_sm {
  width: 20px !important;
  height: 20px;
}

.container_stl {
  display: flex;
  flex-wrap: wrap;
}

.c1 {
  width: 50%;
}
.c2 {
  width: 45%;
}
.c3,
.c4 {
  width: 100%;
}
.c5 {
  width: 60%;
}
.c6 {
  width: 40%;
}
.c40 {
  width: 40%;
}
.c30 {
  width: 30%;
}
.c25 {
  width: 25%;
}
.c20 {
  width: 20%;
}

.c10 {
  width: 10%;
}

.side-menu {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 5%;
}
.side-menu.active {
  width: 15%;
}

.c_body {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 95%;
  position: relative;
  left: 5%;
  min-height: 95vh;
}

.c_body.active {
  width: 85%;
  position: relative;
  left: 15%;
}

.side-menu-safari {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 5%;
}
.side-menu-safari.active {
  width: 16%;
}

.c_body-safari {
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  width: 95%;
  position: relative;
  left: 5%;
  min-height: 95vh;
}

.c_body-safari.active {
  width: 84%;
  position: relative;
  left: 16%;
}

.c_body_loading {
  width: 100%;
  position: relative;
  min-height: 95vh;
}

.img-facom1 {
  width: 100%;
  max-width: 160px;
}

@media (max-width: 2000px) {
  .c_body.active {
    width: 82%;
    position: relative;
    left: 18%;
  }
  .side-menu.active {
    width: 18%;
  }
  .side-menu-safari.active {
    width: 20%;
  }

  .c_body-safari.active {
    width: 80%;
    position: relative;
    left: 20%;
  }
}

@media (max-width: 1600px) {
  .c_body.active {
    width: 80%;
    position: relative;
    left: 20%;
  }
  .side-menu.active {
    width: 20%;
  }
}

@media (max-width: 1500px) {
  .m-title {
    margin-left: 20% !important;
    font-size: var(--size-head1);
    margin-top: 1px;
  }
  .m-title_info {
    margin-left: 50% !important;
    font-size: var(--size-head1);
    margin-top: 1px;
  }
  .side-menu {
    width: 10%;
  }
  .side-menu.active {
    width: 20%;
  }
  .c_body {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body.active {
    width: 80%;
    position: relative;
    left: 20%;
  }
  .side-menu-safari {
    width: 10%;
  }
  .side-menu-safari.active {
    width: 23%;
  }
  .c_body-safari {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body-safari.active {
    width: 77%;
    position: relative;
    left: 23%;
  }
}

@media (max-width: 1500px) {
  .c_body.active {
    width: 77%;
    position: relative;
    left: 23%;
  }
  .side-menu.active {
    width: 23%;
  }
}
@media (max-width: 1400px) {
  .c_body.active {
    width: 78%;
    position: relative;
    left: 22%;
  }
  .side-menu.active {
    width: 22%;
  }
}

/* @media (max-width: 1480px) {
  .side-menu-safari.active {
    width: 26%;
  }

  .c_body-safari.active {
    width: 74%;
    position: relative;
    left: 26%;
  }
} */

@media (max-width: 1350px) {
  .c1 {
    width: 100%;
  }
  .c2 {
    width: 95%;
  }
  .c10 {
    width: 100%;
  }

  .c25 {
    width: 100%;
    margin-top: 10px;
  }
  .side-menu {
    width: 10%;
  }
  .side-menu.active {
    width: 24%;
  }
  .c_body {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body.active {
    width: 76%;
    position: relative;
    left: 24%;
  }
  .side-menu-safari {
    width: 10%;
  }
  .side-menu-safari.active {
    width: 24%;
  }
  .c_body-safari {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body-safari.active {
    width: 76%;
    position: relative;
    left: 24%;
  }
}

@media (max-width: 1250px) {
  .side-menu {
    width: 10%;
  }
  .side-menu.active {
    width: 26%;
  }
  .c_body {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body.active {
    width: 78%;
    position: relative;
    left: 26%;
  }
  .side-menu-safari {
    width: 10%;
  }
  .side-menu-safari.active {
    width: 26%;
  }
  .c_body-safari {
    width: 90%;
    position: relative;
    left: 10%;
  }
  .c_body-safari.active {
    width: 74%;
    position: relative;
    left: 26%;
  }
}

@media (max-width: 1150px) {
  .c1 {
    width: 100%;
  }
  .c2 {
    width: 95%;
  }
  .c10 {
    width: 100%;
  }

  .side-menu {
    transform: translateX(-200%);
    z-index: 1;
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
  }
  .side-menu.active {
    width: 50%;
    z-index: 2;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .c_body {
    width: 100%;
    position: relative;
    left: 0%;
  }
  .c_body.active {
    width: 100%;
    position: fixed;
    position: relative;
    left: 0%;
  }
  .side-menu-safari {
    transform: translateX(-200%);
    z-index: 1;
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
  }
  .side-menu-safari.active {
    width: 50%;
    z-index: 2;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
  .c_body-safari {
    width: 100%;
    position: relative;
    left: 0%;
  }
  .c_body-safari.active {
    width: 100%;
    position: fixed;
    position: relative;
    left: 0%;
  }
}

@media (max-width: 700px) {
  .icon_size {
    width: 30px !important;
    height: 50px;
  }
  .iconbar_size {
    width: 45px !important;
    height: 50px;
  }
  .c30 {
    width: 100%;
  }
  .c40 {
    width: 100%;
  }

  .c20 {
    width: 100%;
  }

  .side-menu {
    z-index: -1;
    width: 0%;
  }
  .side-menu.active {
    width: 70%;
  }
  .side-menu-safari {
    z-index: -1;
    width: 0%;
  }
  .side-menu-safari.active {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .m-title {
    margin-left: 40%;
    font-size: var(--size-head3);
    margin-top: 5px;
  }
  .m-title_info {
    margin-left: 10% !important;
    font-size: var(--size-head1);
    margin-top: 1px;
  }
  .c5 {
    width: 100%;
  }
  .c6 {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .side-menu {
    z-index: -1;
    width: 0%;
  }
  .side-menu.active {
    width: 85%;
  }
  .side-menu-safari {
    z-index: -1;
    width: 0%;
  }
  .side-menu-safari.active {
    width: 85%;
  }
}
.comment-icon {
  text-align: center;
  vertical-align: middle;
  /* position: relative; */
}

.comment-count {
  background: #ffd;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  right: 0px;
  top: 8px;
  font-weight: bold;
}

.content-comment {
  position: relative;
}
