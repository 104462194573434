.dzu-inputLabel {
  font-style: normal;
  border: var(--color-main-text);
  font-size: var(--size-head3);
  line-height: 18px;
  color: var(--color-main-text);
}

.dzu-dropzone {
  border: 1px solid var(--color-main-text);
}
