.success {
  background-color: rgb(237, 247, 237) !important;
  color: rgb(30, 70, 32) !important;
}
.error {
  background-color: rgb(253, 237, 237) !important;
  color: rgb(95, 33, 32) !important;
}
.warning {
  background-color: rgb(255, 244, 229) !important;
  color: rgb(102, 60, 0) !important;
}
.info {
  background-color: rgb(229, 246, 253) !important;
  color: rgb(1, 67, 97) !important;
}
