.alert-stl {
  width: 95%;
  height: 40px !important;
  margin-top: 16px;
}

.alert-stl-val {
  width: 100%;
  height: 40px !important;
  margin-top: 16px;
  margin-bottom: 24px;
}

.fade-in {
  animation: fadeIn 4s;
  -webkit-animation: fadeIn 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
