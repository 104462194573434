* {
  box-sizing: border-box;
}

.h4-stl {
  font-size: var(--size-global1);
}

.h5-stl {
  font-size: var(--size-global2);
}

.header-stl {
  padding: 0;
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  z-index: 905;
  background-color: var(--color-nav-bg);
  color: var(--color-white);

  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.header-pad {
  margin-top: 5vh !important;
}
.nav1 {
  height: 100vh;
}
a {
  color: #007bff;
}
.Right {
  text-align: right;
}

.Center {
  text-align: center;
}

.globalArrow {
  content: "\25BA";
  padding: 0 0.4rem;
  color: #222;
}

.nav-item {
  cursor: pointer !important;
}
.accordion-button {
  background-color: rgb(28, 28, 28) !important;
  color: var(--color-white);
}
#head2 .accordion-button {
  background-color: var(--color-nav-option-bg) !important;
  color: var(--color-white);
  padding-left: 13px;
}
.accordion-button:hover {
  background-color: var(--color-nav-active) !important;
  padding-left: 13px;
}
#head2 .accordion-button:hover {
  background-color: var(--color-nav-active) !important;
  padding-left: 13px;
}
#head2 .accordion-button:focus {
  background-color: var(--color-nav-active) !important;
}
.nav-item:hover {
  background-color: var(--color-nav-active) !important;
}

.accordion-button:not(.collapsed) {
  color: var(--color-white);
  cursor: pointer !important;
}

.languaje_brand {
  margin: 0 5em 0 0;
}
.languaje_brand:hover {
  border-bottom: solid 1px rgb(184, 49, 49);
  cursor: pointer;
}

.accordion-button:focus {
  background-color: var(--color-nav-active) !important;
  border-color: var(--color-white) !important;
  box-shadow: none;
}
.accordion-button.active {
  background-color: var(--color-nav-active) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../images/WhiteArrow.png");
  background-size: 8px;
  transform: rotate(90deg) translateX(8px) translateY(7px);
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important; */
}
.accordion-button::after {
  background-image: url("../images/WhiteArrow.png");
  background-size: 8px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}
.nav-pills .nav-link.active-warranty,
.nav-pills .show > .nav-link {
  background-color: var(--color-nav-active);
}

.numberUsers {
  background: var(--color-main-text);
  color: var(--color-white);
  border-radius: 20px;
  padding: 4px 8px;
  font-size: var(--size-head5);
  margin-left: 5px;
}

.menuMain:hover {
  background-color: var(--color-nav-active);
  border-radius: 10px;
}
.dropdownCircle-stl {
  color: var(--color-input-text);
  border-radius: 2px;
  text-align: left;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: var(--size-head3);
  line-height: 16px;
  max-width: 330px;
  height: 30px;
  border: 1px solid #ced4da;
}

.containerBox {
  background-color: var(--color-white);
  border-radius: 10px;
}

.containerBoxHead {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 2px solid var(--color-input-border);
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.19);
}

.sectionBoxTop {
  border-bottom: 2px solid var(--color-border);
}

.sectionBoxBottom {
  border-top: 2px solid var(--color-border);
}
.imgSearch {
  padding: 10px 15px;
  border-radius: 5px;
}
.imgSearch:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}
.grayBackgr {
  padding: 10px 15px;
  border-radius: 5px;
}

.grayBackgr:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}

.filterBox {
  border-radius: 0;
  border: solid 1px var(--color-border);
  box-shadow: 2px 2px 6px var(--color-border);
}
.modal-90w {
  width: 90vw !important;
  max-width: none;
}
/* Styles Tabs */

.tabs-info .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-white);
  color: var(--color-main-text);
}

.nav-link:hover {
  color: var(--color-main-text);
  background-color: transparent;
}

.tabs-info .nav-link {
  color: var(--color-main-text);
  border-bottom: 1px solid white;
}

.row {
  margin-right: 0;
  margin-left: 0;
}
.bg-gray {
  background-color: rgb(250, 250, 250);
}

.boxgray:hover {
  background-color: var(--color-required);
  padding: 5px;
  cursor: pointer;
}

.cssanimation,
.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInTop {
  animation-name: fadeInTop;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}

@media (max-height: 850px) {
  .header-pad {
    margin-top: 6.5vh !important;
  }
  .nav1 {
    height: 95vh;
  }
}
@media (max-height: 640px) {
  .header-pad {
    margin-top: 8vh !important;
  }
  .nav1 {
    height: 92vh;
  }
}
@media (max-height: 450px) {
  .header-pad {
    margin-top: 11vh !important;
  }
  .nav1 {
    height: 89vh;
  }
}

.warranty-input-disabled {
  background: var(--color-input-disable) !important;
}

.check-table {
  width: 100px;
}

.grayBackground-icon {
  margin-left: 12px;
  padding: 5px 5px;
  margin-top: -2px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.grayBackground-icon:hover {
  background-color: rgba(222, 222, 222);
  cursor: pointer;
}

td {
  min-width: 167px !important;
}
td:first-child {
  min-width: 0;
}

.flex-warranty {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.flex-stl {
  display: flex;
}

.flex-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-space-center {
  justify-content: center;
}

.flex-warranty {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
}

.container-warranty {
  margin: 0 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
}

.container-warranty-pad {
  padding: 0 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
}
.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}
.flex-between {
  justify-content: space-between;
}

.wrap-warranty {
  flex-wrap: wrap;
}

.flex-inputs-responsive {
  align-items: center;
  display: flex;
  gap: 16px;
}

.flex-inputs-responsive-exchange-input {
  align-items: center;
  display: flex;
  gap: 16px;
}

.flex-inputs-responsive-exchange {
  align-items: center;
  display: flex;
  gap: 16px;
}
.flex-inputs-responsive-info {
  align-items: center;
  display: flex;
  gap: 16px;
}
.align-start {
  align-items: baseline;
}

.full-width {
  width: 100%;
}

.container-warranty-header {
  margin: 0 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
}

@media (max-width: 1420px) {
  .flex-inputs-responsive-exchange {
    flex-direction: column;
  }
}
@media (max-width: 1220px) {
  .flex-inputs-responsive-exchange-input {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .modal-90w {
    width: 96vw !important;
  }
}

@media (max-width: 800px) {
  .flex-inputs-responsive {
    flex-direction: column;
  }
  .container-warranty {
    margin: 0 0.9rem;
  }
}
@media (max-width: 450px) {
  .container-warranty {
    gap: 10px;
  }
  .flex-inputs-responsive-info {
    gap: 10px;
  }
}

@media (max-width: 425px) {
  .wrap-warranty {
    justify-content: flex-start;
  }
  .container-warranty-header {
    margin: 0 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
  }
}

@media (max-width: 375px) {
  .nav-link {
    padding: 0.5rem 0.5rem;
    width: 200%;
    max-width: 230px;
  }
  .nav-item {
    min-width: 170px !important;
  }
}

.offcanvas-end {
  z-index: 1100 !important;
}

.offcanvas-backdrop.show {
  z-index: 1070 !important;
}
