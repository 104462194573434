.numberUsers-2 {
  background: var(--color-accepted);
  color: var(--color-main-text);
  border-radius: 20px;
  padding: 4px 8px;
  font-size: var(--size-head5);
  margin-left: 5px;
}

.image-api {
  max-width: 75px;
  max-height: 65px;
}
.fullWidth-image {
  width: auto;
  max-width: 100%;
}
.statusChangeButton {
  display: flex;

  color: var(--color-main-text);
  margin: 12px 5px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.statusChangeButton.refused {
  background-color: var(--color-refused);
}
.statusChangeButton.draft {
  background-color: var(--color-draft);
}
.statusChangeButton.abandoned {
  background-color: var(--color-abandoned);
}

.statusChangeButton.submitted {
  background-color: var(--color-submitted);
}
.statusChangeButton.exchanged {
  background-color: var(--color-exchanged);
}
.statusChangeButton.accepted {
  background-color: var(--color-accepted);
}
.statusChangeButton.available {
  background-color: var(--color-available);
}
.statusChangeButton.recycled {
  background-color: var(--color-recycled);
}
.statusChangeButton.analysis {
  background-color: var(--color-analysis);
}
.statusChangeButton.pdf:hover {
  background-color: #8296a9;
  color: #fff;
}
.statusChangeButton.disabled {
  display: flex;
  cursor: no-drop;
  opacity: 0.5;

  padding: 0 10px;
  border-radius: 8px;
}
