.box-warning {
  margin: 0.5rem 0 !important;
  color: #303030;
  background: #ffd;
  border-color: #ffff91;
  font-size: 0.875rem;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: 300;
}

.sectionBoxTop {
  border-bottom: 2px solid var(--color-border);
  margin-bottom: 1rem;
}
