.lateralWidth{
  width: 30%;
}

@media (max-width: 1200px){
  .lateralWidth{
      width: 40%;
  }
}
@media (max-width: 900px){
  .lateralWidth{
      width: 50%;
  }
}
@media (max-width: 500px){
  .lateralWidth{
      width: 95%;
  }
}

.exchanges .lateralWidth {
  width: 40%;
}

@media (max-width: 1400px) {
  .exchanges .lateralWidth {
    width: 45%;
  }
}

@media (max-width: 1130px) {
  .exchanges .lateralWidth {
    width: 50%;
  }
}
@media (max-width: 900px) {
  .exchanges .lateralWidth {
    width: 90%;
  }
}