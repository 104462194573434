.sun-editor {
  /* width: auto;
    height: auto;
    box-sizing: border-box;
    font-family: Helvetica Neue;
    border: 1px solid #dadada;
    background-color: var(--color-white);
    color: var(--color-main-text);
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; */
  padding: 0 !important;
}

.se-btn-module-border {
  border-radius: 0 !important;
  border: none !important;
  border-right: 1px solid #ced4da !important;
}
