#sure-route p {
  margin-top: 0.75em;
  text-align: justify;
  margin-bottom: 0.75em;
}
#sure-route h1,
#sure-route h2,
#sure-route h3 {
  text-align: center; /* all headings centered */
  clear: both;
}
#sure-route div.centered {
  text-align: center;
} /*work around for IE centering with CSS problem part 1 */
#sure-route div.centered table {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
} /* work around for IE problem part 2 */
body#sure-route {
  margin-left: 10%;
  margin-right: 10%;
}
#sure-route hr.large {
  width: 65%;
  margin-top: 2em;
  margin-bottom: 2em;
}
#sure-route hr.small {
  width: 45%;
  margin-top: 2em;
  margin-bottom: 2em;
}
#sure-route .pagenum {
  /* uncomment the next line for invisible page numbers */
  /* visibility: hidden; */
  position: absolute;
  left: 92%;
  font-size: smaller;
  text-align: right;
} /* page numbers */
#sure-route .sidenote {
  width: 20%;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 1em;
  float: right;
  clear: right;
  margin-top: 1em;
  font-size: smaller;
  color: black;
  background: #eeeeee;
  border: solid 1px;
}
#sure-route .n {
  text-indent: 0%;
}
#sure-route .bl {
  border-left: solid 2px;
}
#sure-route .ispace {
  margin-top: 2em;
}
#sure-route .center {
  text-align: center;
}
#sure-route .smcap {
  font-variant: small-caps;
}
#sure-route .caption {
  font-weight: bold;
}
#sure-route .figcenter {
  margin: auto;
  text-align: center;
}
#sure-route .footnote {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 0.9em;
}
#sure-route .fnanchor {
  vertical-align: super;
  font-size: 0.8em;
  text-decoration: none;
}
#sure-route .gap {
  margin-top: 4em;
}
#sure-route .smallgap {
  margin-top: 2em;
}
#sure-route .poem {
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}
#sure-route .poem br {
  display: none;
}
#sure-route .poem .stanza {
  margin: 1em 0em 1em 0em;
}
#sure-route .poem span.i4 {
  display: block;
  margin-left: 4em;
  padding-left: 3em;
  text-indent: -3em;
}
