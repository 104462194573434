.pillbox-completion {
  background-color: #eaf6ff;
  display: block;
  width: 100%;
  border: solid 0.0625rem #e0e0e0;
  padding: 0.3125rem;
  min-height: 2.5rem;
}
.input-members {
  border: solid 0.0625rem #e0e0e0;
  display: inline-block;
  padding: 0.3125rem;
  margin: 0.125rem;
  background-color: #fff;
  min-width: 140px;
  min-height: 36px;
}

.icon-members {
  display: flex;
  justify-content: flex-end;
  margin: 0 6px;
}

.icon-color {
  opacity: 0.5;
}

.icon-color:hover {
  opacity: 1;
}

.selected {
  background-color: #ffef8d !important;
}