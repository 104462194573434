@keyframes LogoMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.container__LogoMove {
  animation-name: LogoMove;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.color1 {
  background-color: var(--color-loading-bg-primary) !important;
}
.color2 {
  background-color: var(--color-loading-bg-secondary) !important;
}

.css-t752vm {
  background-color: rgb(28, 28, 28) !important;
}
