/* .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  position: initial !important;
  padding: 0 !important;
  font-size: 12px !important;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  position: initial !important;
  width: 100%;
  height: 30px;
  margin-top: -34px;
  border: 0;
} */
.MuiAutocomplete-inputRoot {
  border: 0px !important;
}

.MuiAutocomplete-input {
  padding: 0 10px !important;
  border: 0px !important;
}
.auto-warranty * {
  border-top: 0 !important ;
  border-right: 0 !important ;
  border-left: 0 !important ;
}

.inputfocus {
  width: 10vw !important;
  background-color: #555 !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.inputfocus:focus {
  width: 30vw !important;
  background-color: #fff !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.inputfocus:hover {
  width: 30vw !important;
  background-color: #fff !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.inputfocus:active {
  width: 30vw !important;
  background-color: #fff !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.inputfocus:visited {
  width: 30vw !important;
  background-color: #fff !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.inputfocusActive {
  width: 30vw !important;
  background-color: #fff !important;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.card-search {
  font-size: 12 !important;
}

.cardSearch {
  background-color: #555;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cardSearch.focus {
  background-color: #fff;
}
.searchicon {
  margin-left: -61vw;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}

.searchiconfocus {
  margin-left: -21vw;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
.clearicon {
  margin-left: -64vw;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
.cleariconfocus {
  margin-left: -23vw;
  transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
}
