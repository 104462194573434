.filterBox {
  border-radius: 0;
  border: solid 1px var(--color-border);
  box-shadow: 2px 2px 6px var(--color-border);
}

.bg-gray {
  background-color: rgb(250, 250, 250);
}

.cssanimation,
.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.table-stl {
  border-collapse: collapse;
  width: 100%;
  font-size: var(--size-head3);
}

.table-stl-td {
  border-top: solid 1px #333;
  border-bottom: solid 1px #333;
  padding-right: 30px;
}
.cssanimation span {
  display: inline-block;
}

.fadeInTop {
  animation-name: fadeInTop;
}
@keyframes fadeInTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
  }
}
