.container-exchanges {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.exchanges-input {
  width: 100%;
}

@media (max-width: 900px) {
  .container-exchanges {
    flex-direction: column;
  }
}
